<script setup lang="ts">
const props = defineProps<{
  data?: any
  currency?: any
  nopayment?: boolean
}>()

const { t } = useI18n()
</script>

<template>
  <div v-if="props?.data" class="space-y-3 mx-auto">
    <div v-if="props?.data?.id" class="my-5 text-2xl sm:text-xl text-gray-500 font-bold">
      {{ t('order_code') }}:
      <span class="uppercase ml-1">
        {{ props?.data?.id }}
      </span>
    </div>

    <PaymentInfo
      v-if="props?.data?.id && !props?.nopayment"
      :pay-total="(props?.data?.total ?? 0) * 1"
      :pay-message="props?.data?.customer_phone ? `${props?.data?.id} ${props?.data?.customer_phone}` : `${props?.data?.id}`"
      :pay-bank="props?.data?.shopdata"
      :currency="props?.data?.currency"
      class="m-auto my-5"
    />

    <OrderResultLink :data="props?.data?.id" />
  </div>
</template>
